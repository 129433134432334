<template>

  <div id="bindcard">
    <rxNavBar title="绑定银行卡" ></rxNavBar>
    <div class="cell-normal-addRadius addMarginTop">
      <span class="maker" :class="{'orangeColor':openingBankName.toString().trim().length>0}">•</span>
      <span class="cell-title">姓名</span>
      <span class="content-divide">|</span>
      <span><input  type="text" placeholder="输入开户姓名"  class="input-text"  v-model="openingBankName" @blur="checkName" /></span>
    </div>
    <div class="cell-normal-addRadius" >
      <span class="maker" :class="{'orangeColor':bankCard.toString().trim().length>0}">•</span>
      <span class="cell-title">卡号</span>
      <span class="content-divide">|</span>
      <span>
                <input  type="text" style="width:195px" class="input-text font-bold" placeholder="输入开户卡号"
                        maxlength="23"  oninput = "value=value.replace(/[^\d]/g,'')"
                        @blur="bankCardKeyup"  v-model="bankCard" />
            </span>
    </div>
    <div class="cell-normal-addRadius"@click="showPicker = true">
      <span class="maker" :class="{'orangeColor': bankOfDeposit.dictionaryTitle}">•</span>
      <span class="cell-title">开户银行</span>
      <span class="content-divide">|</span>
      <span class="input-text">{{bankOfDeposit.dictionaryTitle}}</span>
    </div>
    <div class="cell-normal-addRadius" >
      <span class="maker" :class="{'orangeColor': subBank.toString().trim().length>0}">•</span>
      <span class="cell-title">支行</span>
      <span class="content-divide">|</span>
      <span><input  type="text"  class="input-text"  v-model="subBank"  placeholder="填写支行"/></span>
    </div>
    <div class="cell-normal-addRadius" >
      <span class="maker" :class="{'orangeColor': salesDepartmentNo.toString().trim().length>0}">•</span>
      <span class="cell-title">行号</span>
      <span class="content-divide">|</span>
      <span><input  type="text"  class="input-text"  v-model="salesDepartmentNo"  placeholder="填写行号"/></span>
    </div>
    <!--        <div class="cell-normal-addRadius" >-->
    <!--            <span class="maker"  :class="{'orangeColor': customTel.toString().length>0}">•</span>-->
    <!--            <span class="cell-title">银行预留手机号</span>-->
    <!--            <span class="content-divide">|</span>-->
    <!--            <span>-->
    <!--                <input  type="text"  placeholder="输入预留手机号" class="input-text" maxlength="11"-->
    <!--                        onkeyup="this.value=this.value.replace(!/^1[34578]\d{9}$/,'')"-->
    <!--                        @blur="testPhoneNum" v-model="customTel" />-->
    <!--            </span>-->
    <!--        </div>-->
    <van-button  class="save-bind-Button_Disable" :class="{'save-bind-Button_Enable': buttonEnable}" :disabled="!buttonEnable" v-on:click="bind" >提交
    </van-button>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          show-toolbar
          :columns="bankList"
          @cancel="showPicker = false"
          :default-index="defaultIndex"
          value-key="dictionaryTitle"
          @confirm="onConfirm"
      />
    </van-popup>

  </div>

</template>

<script>


import {
  NavBar,
  Button,
  Picker,
  Popup, Toast
} from 'vant';
import {bankCardBind, queryBaseData, queryPersonDetails} from "../../../getData/getData";
import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import config from "@/view/contract/config";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    rxNavBar
  },
  data(){
    return {
      user_id:'',
      openingBankName:'',
      //卡号
      bankCard:'',
      //开户银行
      bankOfDeposit:{},
      bankList: [],
      showPicker: false,
      defaultIndex: '',
      customTel:'',
      //支行
      subBank: '',
      //行号
      salesDepartmentNo: ''
    }
  },
  created() {
    this.queryPersonDetails();
    this.initDicData();
  },
  methods: {

    //返回上一层
    leftReturn(){
      this.$router.go(-1);
    },


    queryPersonDetails(){
      let that = this;
      let queryPersonDetailsData={}
      queryPersonDetailsData.user_id= globaluserId()
      queryPersonDetails(queryPersonDetailsData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          let data = response.data.data
          if(data.isBankCode){
            that.openingBankName = data.openingBankName || ''
            that.bankCard = data.bankCode ||''
            that.bankOfDeposit.dictionaryTitle = data.openingBank || ''
            that.bankOfDeposit.id = data.openingBankId || ''
            that.subBank = data.subBank || ''
            that.salesDepartmentNo = data.salesDepartmentNo || ''
          }
        })
      })

    },
    //初始化字典数据
    initDicData:function(){
      const that = this
      let initData = {}
      initData.dbName = ["bankList"]
      initData.fdName = []
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.bankList)
          that.bankList = response.data.data.bankList
          console.log(that.bankList)
        })
      })
    },
    onConfirm(value,index){
      if(value){
        this.bankOfDeposit = value
        this.defaultIndex = index
      }
      this.showPicker = false

    },

    checkName() {
      var re = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
      if(this.openingBankName){
        if(!re.test(this.openingBankName)){
          responseUtil.alertMsg(this,'请输入正确的姓名')
          this.openingBankName = '';
        }
      }
    },

    bind(){
      let _this=this;
      let bindBankCardData={}

      bindBankCardData.user_id = globaluserId();
      bindBankCardData.bankCard=_this.bankCard;
      bindBankCardData.openingBank=_this.bankOfDeposit.id;
      bindBankCardData.name = _this.openingBankName;
      bindBankCardData.subBank = _this.subBank
      bindBankCardData.salesDepartmentNo = _this.salesDepartmentNo

      console.log(bindBankCardData)

      bankCardBind(bindBankCardData).then(function (response) {
        responseUtil.dealResponse(_this, response, () => {
          console.log(response)
          if(200==response.status){
            responseUtil.alertMsg(_this,'绑定成功')
            _this.$router.replace('SetPersonalInfo')
          }else {
            responseUtil.alertMsg(_this,'绑定失败')
          }
        })
      }).catch(function (err) {
            console.log("操作失败")
          }
      )
    },
    bankCardKeyup () {

      var re = /^[1-9]\d{9,29}$/
      if(this.bankCard){
        if(!re.test(this.bankCard)){
          responseUtil.alertMsg(this,'请输入正确的银行卡号')
          this.bankCard = '';
        }
      }
    },

  },
  computed:
      {
        buttonEnable () {
          return (this.openingBankName.toString().trim().length>0 && this.bankCard.toString().trim().length>0 && this.bankOfDeposit.dictionaryTitle && this.subBank.toString().trim().length>0 && this.salesDepartmentNo.toString().trim().length>0)
        }
      }
}
</script>


<style lang="less" scoped>
#bindcard{
  @container_width:9rem;
  @cell_height:50px;

  .cell-normal-addRadius{
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    width:@container_width;
    height: @cell_height;
    margin: 13px auto;
  }
  .addMarginTop{
    margin-top: 25px;
  }
  .maker{
    height: @cell_height;
    line-height: @cell_height;
    color: #999999;
    float: left;
    padding-left: 12px;
    padding-right: 11px;
    font-size: 24px;
  }
  .cell-title{
    float: left;
    font-size: 15px;
    font-weight: bold;
    height: @cell_height;
    //margin-left: 13px;
    line-height: @cell_height;
  }
  .content-divide{
    float: left;
    font-size: 15px;
    color: #efefef;
    margin:0 10px;
    height: @cell_height;
    line-height: @cell_height;
  }
  .input-text{
    //vertical-align:middle;
    float: left;
    display: inline;
    //color: #999999;
    font-family: ATTriumvirateCondensed;
    font-size: 15px;
    border: none;
  }
  .font-bold{
    font-weight: bold;
  }

  .save-bind-Button_Disable{
    background-color: rgba(184, 184, 184, 0.2);
    width: 9rem;
    height: 1.22rem;
    border-radius: 8px;
    margin-top: 180px;
    margin-left: 19px;
    //margin:30px 19px;
    font-size: 18px;
    color: white;
    line-height: 1.22rem;
    text-align: center;
  }
  .save-bind-Button_Enable{
    background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

  }
  .orangeColor{
    color: #ff3c00;
  }


  input::-webkit-input-placeholder{/*Webkit browsers*/
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /*  Mozilla Firefox 4-18使用伪类 */
  input:-moz-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /* Mozilla Firefox 19+ 使用伪元素  */
  input::-moz-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /* IE10使用伪类 */
  input:-ms-input-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
}
</style>
